<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
    title="添加组员"
    :visible.sync="isShow"
    width="720px"
  >
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="所属组">
          <el-input disabled v-model="groupName"></el-input>
        </el-form-item>
        <el-form-item label="组员">
          <el-transfer
            :props="props"
            v-model="value"
            :data="users"
            :titles="['待选人员', '已选人员']"
          ></el-transfer>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="hide" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini" :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "addGroupUsers",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    groupName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      props: {
        key: "id",
        label: "name",
      },
      dialogVisible: true,
      form: {
        groupId: "",
        userIds: [],
      },
      users: [],
      value: [],
    };
  },
  methods: {
    ...mapActions("teacherGrouping", ["selectTeacherLeader","addTeacherGroupUsers"]),

    onSelect(v) {
      this.form.groupUsers = v;
      console.log(v);
    },
    hide() {
      this.value = [];
      this.$emit("hide");
    },
    submit() {
      this.loading = true;
      let form = {
        group_id:this.groupId,
        user_ids:this.value
      }
      this.addTeacherGroupUsers(form)
        .then((res) => {
          if (res.ret === 0) {
            this.$emit("hide");
            this.$message.success("添加成功");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.selectTeacherLeader().then((res) => {
      if (res.ret === 0) this.users = res.data;
    });
  },
};
</script>

<style>
.el-transfer__button:first-child {
  margin-bottom: 0;
}
</style>
